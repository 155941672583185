import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";

import "./member-agreement.scss";

// import Button from "../components/button/button"
// import SectionHeading from "../components/section-heading/section-heading"
// import ContactSection from "../components/contact-section/contact-section"
import CappedWidth from "../components/capped-width/capped-width";

//Import images from image.js here:
// import { EllenWreath } from "../components/image"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="privacy-policy" />

    {/* Privacy Policy Page */}
    <div className="section privacy-policy">
      <CappedWidth>
        <h1 className="page-title">Privacy Policy</h1>
        <p>
          <b>How We Use Information Provided by You</b>
        </p>
        <p>
          This site does not collect personal information about individuals
          except when specifically and knowingly provided by such individuals.
          We will not sell, trade, rent or give your personal information to
          third parties without your permission. To enable members to subscribe
          to discussion lists and receive newsletters and announcements,
          members' email addresses may be shared with a third party who provides
          this service. But members will never be subscribed to any Online
          Writing Workshop mailing list without their permission, and will be
          unsubscribed on request at any time.
        </p>
        <p>
          Online Writing Workshops will not use your email address or other
          personal information to send you messages about anything other than
          our Online Writing Workshops, with one exception: we may occasionally
          send out a message on behalf of any sponsor partners about their books
          of interest to members. This will not occur more than quarterly (four
          times per year).
        </p>
        <p>
          Members should be aware that when they voluntarily disclose personal
          information (e.g., name, email address) on the mailing lists or for a
          workshop's Member Directory, that information can be collected and
          used by others and may result in unsolicited messages from entities
          other than Online Writing Workshops. Online Writing Workshops'
          membership agreement prohibits such usage of members' personal
          information, but we cannot guarantee complete compliance.
        </p>

        <p>
          <b>Cookies</b>
        </p>
        <p>
          Like many others, our site works by placing a "cookie" in the
          appropriate file area of members' Web-browser software. The cookie is
          used to establish a session on our site, so that members do not have
          to log in over and over again. It is possible to use our site if your
          browser is set to reject cookies; it will just be a bit more
          cumbersome, since you will probably have to log in more than once. The
          cookie does not store or record any personal information.
        </p>

        <p>
          <b>Members Under 18</b>
        </p>
        <p>
          Members under 18 must obtain the permission of their parent(s) or
          guardian(s) before joining. We encourage parents and guardians to get
          involved with their children's online usage and to be aware of the
          activities in which they are participating. If you have questions
          about our privacy policy, <Link to="/#contact">contact us</Link>.
        </p>
        <br />
      </CappedWidth>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
